<template>
  <v-container fill-height class="d-flex align-items-center justify-center">
    <v-card min-width="600" elevation="3">
      <v-card-title class="text-h5 primary white--text">
        Статус миграции
      </v-card-title>

      <v-card-text>
        <div class="text-body-1 my-2"> Адрес портала-донора: {{ auth.domain }} </div>
        <div class="text-body-1 mb-3">Хук портала акцептора: {{ hook }}</div>
        <v-divider> </v-divider>
        <status-field
          :finish="totals['lead.userfield'].finish"
          :last_id="totals['lead.userfield'].last_id"
          help="пользовательские поля (лиды)"
        />
        <status-field
          :finish="totals['contact.userfield'].finish"
          :last_id="totals['contact.userfield'].last_id"
          help="пользовательские поля (контакты)"
        />
        <status-field
          :finish="totals['company.userfield'].finish"
          :last_id="totals['company.userfield'].last_id"
          help="пользовательские поля (компании)"
        />
        <status-field
          :finish="totals['deal.userfield'].finish"
          :last_id="totals['deal.userfield'].last_id"
          help="пользовательские поля (сделки)"
        />
        <status-field
          :finish="totals.lead.finish"
          :last_id="totals.lead.last_id"
          help="лиды"
        />
        <status-field
          :finish="totals.contact.finish"
          :last_id="totals.company.last_id"
          help="контакты"
        />
        <status-field
          :finish="totals.company.finish"
          :last_id="totals.company.last_id"
          help="компании"
        />
        <status-field
          :finish="totals.deal.finish"
          :last_id="totals.deal.last_id"
          help="сделки"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusField from './../components/StatusField.vue'
const axios = require('axios')

export default {
    name: 'Status',
    components: {
      StatusField
    },
    computed: { ...mapGetters(['auth', 'hook']) },
    data: () => ({
      interval: null,
      statuses: {},
      totals: {
        'lead.userfield': { finish: false, last_id: 1 },
        'deal.userfield': { finish: false, last_id: 1 },
        'company.userfield': { finish: false, last_id: 1 },
        'contact.userfield': { finish: false, last_id: 1 },
        lead: { finish: false, last_id: 1 },
        deal: { finish: false, last_id: 1 },
        company: { finish: false, last_id: 1 },
        contact: { finish: false, last_id: 1 }
      },
    }),
    methods: {
      updateStatus: async function() {
        let data = await axios.get(`https://d5d3vso4l56atafl0mu8.apigw.yandexcloud.net/api/upload/status?acceptor=${this.hook}&donor=${this.auth.domain}`)
        console.log(data)

        this.statuses = data.data.statuses
        this.totals = data.data.totals

        if (this.totals.activity.finish && this.totals.lead.finish && this.totals.deal.finish && this.totals.company.finish && this.totals.contact.finish) {
          clearInterval(this.interval)
        }
      }
    },
    mounted() {
      this.interval = setInterval(this.updateStatus, 5000)
    }
}
</script>