<template>
    <div class="text-body-1 black--text my-2 d-flex align-center">
        <v-progress-circular
            v-if="!finish"
            size="18"
            width="2"
            color="primary"
            indeterminate
        ></v-progress-circular>
        <v-icon v-else color="success">mdi-check-circle</v-icon>
        <span class="ml-4"> {{ finish ? "Успешно перенес" : `Выгружаю (последний выгруженный ID: ${last_id})` }} {{ help }}</span>
    </div>
</template>

<script>
export default {
    name: 'StatusField',
    props: {
        finish: {
            type: Boolean,
            default: false
        },
        last_id: {
            type: Number,
            default: 0
        },
        help: {
            type: String,
            default: ''
        }
    }
}
</script>